import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import RelativeWrapper from '../../components/layout/RelativeWrapper';

import { ViewportBreakpoints } from '../../components/cssConstants';

import FeatureListBreaker from '../../components/cta/FeatureListBreaker';
import GetStarted from '../../components/cta/GetStarted';
import FeatureRow from '../../components/section/FeatureRow';
import ClientLogoBar, { ClientLogos } from '../../components/section/ClientLogoBar';
import CaseStudyCarousel from '../../components/section/CaseStudyCarousel';

import CheckmarkTextList from '../../components/frills/CheckmarkTextList';

import HeroH1 from '../../components/hero/HeroH1';
import HeroH2 from '../../components/hero/HeroH2';

import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const DarkWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand180};
  background-repeat: no-repeat;

  background-position: center bottom;
  background-size: 100% auto;
  background-image: url(${props => props.backgroundMobile});

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    background-position: left calc(40% - 550px) top;
    background-size: auto 100%;
    background-image: url(${props => props.backgroundDesktop});
    transform: translate3d(0, -80px, 0);
  }
`;

const Hero = styled.div`
  padding: 80px 0 470px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 210px 0 170px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
    justify-content: flex-end;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 40%;
  }
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const Subtitle = styled(Text)`
  margin: 20px 0 40px 0;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme: { colors } }) => colors.grey};
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
};

const Page = ({ data }) => (
  <Body header={header}>
    <SEO
      title="Retail Scheduling Software | Rotaready"
      description="Our retail employee scheduling software takes the hassle out of rota planning & tracking staff attendance, from small shops to large retail chains."
      url="retail"
    />
    <DarkWrapper
      backgroundDesktop={data.heroBackgroundDesktop.childImageSharp.fixed.src}
      backgroundMobile={data.heroBackgroundMobile.childImageSharp.fixed.src}
    >
      <ResponsiveContainer>
        <Hero>
          <HeroInnerContainer>
            <HeroBody>
              <HeroH1 color="brand" text="Retail" />
              <HeroH2 color="white" text="Effortless employee scheduling for retailers" />

              <H4 uistyle="white">
                Our retail employee scheduling software takes the hassle out of rota planning and tracking staff attendance, whether you run a small shop or a large retail chain.
                <br />
                <br />
                Rotaready's retail scheduling software provides complete control over schedules, saves admin time and empowers your employees.
              </H4>

              <CtaButtons>
                <Link to="/demo">
                  <Button uistyle="primary" text="Request demo" size="lg" />
                </Link>
                <Link to="/contact">
                  <Button uistyle="white" text="Speak to the team" size="lg" ghost borderless />
                </Link>
              </CtaButtons>
            </HeroBody>
          </HeroInnerContainer>
        </Hero>
      </ResponsiveContainer>
    </DarkWrapper>

    <ClientLogoBar
      logos={[
        ClientLogos.BATHSTORE,
        ClientLogos.EE,
        ClientLogos.O2,
        ClientLogos.OUTDOOR_AND_COUNTRY,
        ClientLogos.PANDORA,
        ClientLogos.STORMFRONT,
      ]}
    />

    <FeatureRow
      mirrorArrange
      title="Designed for bricks and mortar retailers from the ground up"
      illustrationImage={data.venuePickerIllustration.childImageSharp.fluid}
      illustrationAltText="Switching between retail stores within Rotaready"
    >
      <Subtitle text="Our cloud based rota software gives you complete control over your schedules and your people, across any number of stores." />

      <CheckmarkTextList
        items={[
          'Plan and approve staff rotas in line with demand forecasts',
          'Track staff attendance and timekeeping across the entire business',
          'Budget by hours, revenue or wages, with real time labour costs',
          (
            <>
              Streamline <Link to="/payroll" style="color: inherit;">payroll</Link> with instant timesheets, validated and rounded
            </>
          )
        ]}
      />
    </FeatureRow>

    <FeatureRow
      backgroundColor="brand10"
      title="Get back onto the shop floor"
      illustrationImage={data.automatedSchedulingIllustration.childImageSharp.fluid}
      illustrationAltText="Automatically allocating shifts to retail staff within Rotaready"
    >
      <Subtitle text="Stuck in the back-room drowning in paperwork? Rotaready saves you hours of admin time by building your staff rota automatically. It even takes care of all the tricky requirements found in retail shift planning." />

      <LinkWithArrow
        direction="right"
        to="/rota-scheduling"
        text="Find out more about scheduling"
        render={props => <Link {...props} />}
      />
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="Empower your employees"
      illustrationImage={data.mobileScheduleIllustration.childImageSharp.fluid}
      illustrationAltText="Retail employee view of upcoming shifts within Rotaready"
    >
      <Subtitle text="Built with retail assistants in mind, the Rotaready app has everything they need to stay informed and engaged. No more shift mix-ups or late arrivals." />

      <CheckmarkTextList
        items={[
          'Real-time shift update notifications & rota changes',
          'Book holiday, view this year\'s allowance or accrued hours',
          'Swap shifts amongst colleagues',
          'Clock-in and out from within the app, using secure GPS',
          'Pick up extra hours with Shift Broadcasts',
          'Increase staff engagement and drive retention',
        ]}
      />
    </FeatureRow>

    <FeatureRow
      backgroundColor="brand10"
      title="Schedule to demand and drive business performance"
      illustrationImage={data.demandVsBookingsIllustration.childImageSharp.fluid}
      illustrationAltText="Viewing number of staff vs footfall within Rotaready"
    >
      <Subtitle text="Predicting the demand on your store is a difficult task, but it's crucial in determining how many team members to place on shift. Rotaready can analyse your revenue, footfall and more, to deliver accurate demand forecasts ahead of time." />

      <CheckmarkTextList
        columns={1}
        items={[
          'Automatic sales predictions, powered by Rotaready\'s artificial intelligence',
          (
            <>
              Bring everything together in one platform with our included <Link to="/integrations" style="color: inherit;">integrations</Link>, from EPOS to footfall
            </>
          )
        ]}
      />

      <LinkWithArrow
        direction="right"
        to="/demand-forecasting"
        text="Learn about demand forecasting"
        render={props => <Link {...props} />}
      />
    </FeatureRow>

    <FeatureRow
      mirrorArrange
      title="Tick all the boxes"
      illustrationImage={data.rotaValidationIllustration.childImageSharp.fluid}
      illustrationAltText="Staff scheduling validation issues highlighted within Rotaready"
    >
      <Subtitle text="Rest easy in the knowledge that your rotas and your employees are fully compliant with the rules, covering everything from guaranteed hours through to the Working Time directive." />

      <CheckmarkTextList
        items={[
          'Compliance with every aspect of the Working Time regulations',
          'Secure staff document storage with alerts before they expire',
          'Live rota validation, ensuring contractual hours are covered',
          'Fully compliant with GDPR and other Data Protection legislation',
        ]}
      />
    </FeatureRow>

    <CaseStudyCarousel />

    <RelativeWrapper backgroundColor="white">
      <FeatureListBreaker displayCopy rows={2} />
    </RelativeWrapper>

    <GetStarted />
  </Body>
);

export default Page;

export const query = graphql`
  query {
    heroBackgroundDesktop: file(relativePath: { eq: "misc/hero-retail-desktop.png" }) {
      childImageSharp {
        fixed(height: 1580, width: 2438, quality: 30) {
          src
        }
      }
    }

    heroBackgroundMobile: file(relativePath: { eq: "misc/hero-retail-mobile.png" }) {
      childImageSharp {
        fixed(height: 894, width: 750, quality: 30) {
          src
        }
      }
    }

    venuePickerIllustration: file(relativePath: { eq: "illustrations/venue-picker.png" }) {
      childImageSharp {
        fluid(maxWidth: 442, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    automatedSchedulingIllustration: file(relativePath: { eq: "illustrations/automated-scheduling.png" }) {
      childImageSharp {
        fluid(maxWidth: 536, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    mobileScheduleIllustration: file(relativePath: { eq: "illustrations/mobile-schedule.png" }) {
      childImageSharp {
        fluid(maxWidth: 489, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    demandVsBookingsIllustration: file(relativePath: { eq: "illustrations/demand-vs-bookings.png" }) {
      childImageSharp {
        fluid(maxWidth: 519, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    rotaValidationIllustration: file(relativePath: { eq: "illustrations/rota-validation.png" }) {
      childImageSharp {
        fluid(maxWidth: 465, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
